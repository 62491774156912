import { NavLink } from 'react-router-dom';
import './Footer.scss';

function Footer() {
  return (
    <footer style={{backgroundImage: 'url(/img/footer_throat.svg)'}}>
      <div className="social_container">
        <a href="https://www.facebook.com/MrDaySiamoNoi/" target="_blank" rel="noreferrer" className="social_link">
          <img src="/img/social/facebook.svg" alt="" />
        </a>
        <a href="https://www.instagram.com/mr.dayofficial/" target="_blank" rel="noreferrer" className="social_link">
          <img src="/img/social/instagram.svg" alt="" />
        </a>
        <a href="https://www.twitch.tv/mrdayofficial" target="_blank" rel="noreferrer" className="social_link">
          <img src="/img/social/twitch.svg" alt="" />
        </a>
        <a href="https://www.tiktok.com/@mr.dayofficial" target="_blank" rel="noreferrer" className="social_link">
          <img src="/img/social/tiktok.svg" alt="" />
        </a>
      </div>
      <p className="copy">® Vicenzi S.p.A. Capitale Sociale € 3.900.000,00 I.V.
        Registro Imprese di Verona n. 00227320231 C.C.I.A.A.114031-Meccanog.n: VR012884 Cod Fisc. e P.IVA n. 00227320231
        Sede: Via Forte Garofolo, 1 - 37057 San Giovanni Lupatoto - Verona - Italia</p>
        <div className='footer_link'>
          <NavLink className="cookie_link" to="/cookie-policy">Cookie Policy</NavLink>
           <NavLink className="cookie_link" to="/privacy-policy">Privacy Policy</NavLink>
          </div>
        <div className='footer_link'>
        <a href='https://www.vicenzi.it/valori/whistleblowing' target='_blank'>Procedura di whistleblowing</a>
          </div>
    </footer>
  );
}

export default Footer;
import { NavLink } from 'react-router-dom';
import Hamburger from 'hamburger-react'
import React, { useState } from "react";
import { HashLink } from 'react-router-hash-link';

import './Header.scss';

function Header(props) {
    let header_logo = "";
    if (props.logo!== undefined){
        header_logo = <NavLink to="/" onClick={() => window.scrollTo(0, 0)}><img src={props.logo} className="logo" alt="mrday_logo"></img></NavLink>;
    }

    
    const [isActive, setActive] = useState("false");

    const handleToggle = () => {
        setActive(!isActive);
    };
    return (
        <header>
            {header_logo}
            <Hamburger size={50}  onToggle={handleToggle}/>
            <div id='main_menu' className={isActive ? null : "open"}> 
                <ul>
                    <li>
                        <NavLink to="/" onClick={function(){window.scrollTo(0, 0); setActive("false")}}>HOME</NavLink>
                    </li>
                    <li>
                        <NavLink to="/players" onClick={function () {window.scrollTo(0, 0); setActive("false")}}>PLAYERS</NavLink>
                    </li>
                    <li>
                        <HashLink to='/#products' onClick={function () { setActive("false") }} >PRODUCTS</HashLink>
                    </li>
                </ul>
            </div>
        </header>


    )
}
export default Header;